import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";

Modal.setAppElement("#root");

ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
  <CssBaseline />
  <App />
</ThemeProvider>
  ,
  document.getElementById("root")
);
