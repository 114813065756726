import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import {ReactComponent as EmailNewsletterIconBase } from "../../images/email-newsletter-icon.svg"
import {Container as ContainerBase } from "components/misc/Layouts.js"
import {SectionHeading} from "components/misc/Headings.js";
import {PrimaryButton} from "components/misc/Buttons.js";

import * as ConsultaWeb from "../../services/clientes/consultaWeb";


const Container = tw(ContainerBase)`bg-secondary-900 -mx-8`
const Content = tw.div`mx-auto py-20 lg:py-24 m-16`;
//const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 m-16`;

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8 pr-5`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-16 h-16 text-green-500`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-500 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none mx-auto`
const Input = tw.input`w-full sm:w-auto block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200 mr-5 mb-5`
const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`
//const H2 = tw.h2`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`


export default () => {

  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [cargando, setCargando] = useState(false);
  const [mensaje, setMensaje] = useState("");
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email !== "" && telefono !== "") {
      //console.log('Mail:', email);
      let data = { 
        email: email,
        telefono: telefono,
        boletin: true,
        origen: "BOLETIN"
      };
      setCargando(false);
      ConsultaWeb.insert(data).then((res) => {
        setMensaje("Enviado correctamente");
      }).catch((error) => {
        setMensaje("Fallo al enviar correo. Contacte por WhatsApp");
      });
    } else {
      if(telefono === "") {
        setMensaje("Debes ingresar tu número de teléfono");
      } else {
        setMensaje("Debes ingresar tu email");
      }
    }
    setTimeout(() => {
      setMensaje("");
    }, 5000);
  }

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      setTelefono("");
      setEmail("");
    }
  }, [cargando])

  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Boletín de novedades</Heading>
              <Description>Recibe en tu email oportunidades de compra.</Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
          <Form onSubmit={handleSubmit}>
            <Input name="newsletterphone" type="text" placeholder="Tu telefono" value={telefono} onChange={(e) => {
              setTelefono(e.target.value);
              //console.log('Telefono', telefono);
            } } />
            <Input name="newslettermail" type="email" placeholder="Tu email" value={email} onChange={(e) => {
              setEmail(e.target.value);
              //console.log('Email', email);
            } } />
            <Button type="submit">ENVIAR</Button>
            <div style={{color: "white", marginTop: 30}}>
              <h3>{mensaje}</h3>
            </div>
          </Form>
          </FormColumn>
        </Row>
      </Content>
    </Container>
  );
};
