import GestorAxios from "../../config/axios";
//import Permisos from "../../config/permisos";

/*
export async function getAllItems() {
  let data = {};
  await GestorAxios.get("/web/consulta").then((res) => {
    data = res.data;
  });
  return data;
}
*/

export async function insert(data) {
  let dataReturn = {};
  //console.log(data);
  try {
    await GestorAxios.post("/web/consulta/nuevo/", data).then((res) => {
      dataReturn = res;
    });
  } catch (err) {
    console.log("Error en servicio", err);
  }

  return dataReturn;
}
