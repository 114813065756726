import React, { useEffect, useState } from "react";

import Checkbox from "@material-tailwind/react/Checkbox";
import Pie from "../../common/Pie";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading, Subheading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import ContactoFlotante from "pages/common/ContactoFlotante";

import * as LoteosServices from "../../../services/productos/loteosWeb";

import GestorUrls from "../../../config/urls";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HeadingRow = tw.div` flex w-full   `;
const Heading = tw(
  SectionHeading
)` text-gray-100 pt-20  h-full text-3xl sm:text-5xl sm:text-4xl lg:text-5xl `;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostHeader = styled.div`
  ${tw`mt-10 w-full mt-0 sm:w-1/2 lg:w-1/3 sm:pr-8 `}
  ${(props) =>
    css`
      ${tw`w-full! `}
    `}
`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none  bg-orange-100  `;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

//const Subtitulo = tw(Subheading)`mb-6 text-justify`;
const Titulo = tw(SectionHeading)`w-full mb-10 mt-8`;

export default ({
  headingText = "Loteos",
  /*posts = [
     
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
  ],*/
}) => {
  const classes = useStyles();

  const [visible, setVisible] = useState(9);
  const [habitaciones, setHabitaciones] = React.useState("");
  const [ubicacion, setUbicacion] = React.useState("");
  const [superficie, setSuperficie] = React.useState("");
  const [banios, setBanios] = React.useState("");
  const [cocheras, setCocheras] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [loteos, setLoteos] = useState({});
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    if (!cargando) {
      LoteosServices.visibleWeb().then((res) => {
        setLoteos(res);
        //console.log(res)
      });
      setCargando(true);
    }
  }, [loteos]);

  const urlDrive = GestorUrls.drive;

  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  return (
    <AnimationRevealPage>
      <ContactoFlotante />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <Posts>
            <PostHeader>
              <Image imageSrc="https://tripin.travel/wp-content/uploads/2016/08/cba_santarosa17-secretaria-9.jpg">
                <Heading>{headingText}</Heading>
              </Image>
            </PostHeader>
            <Titulo>El lugar de tus sueños</Titulo>
            {/*<Subtitulo>Loteos exclusivos</Subtitulo>*/}
            {loteos ? (
              <>
                {loteos.length ? (
                  <>
                    {loteos.slice(0, visible).map((loteo, index) => (
                      <>
                        {/*console.log('Loteo desde Index Loteo: ', loteo)*/}
                        <PostContainer key={index} featured={loteo.featured}>
                          {/*console.log('Reemplazo de nombre: ', loteo.nombre.replace(/ /g, "-"))*/}
                          <Post
                            className="group"
                            as="a"
                            href={
                              "/producto/detalle/lote/" +
                              loteo.id +
                              "/" +
                              loteo.nombre.replace(/ /g, "-")
                            }
                          >
                            <Image
                              imageSrc={
                                urlDrive +
                                "productos/loteos/" +
                                loteo.id +
                                "/" +
                                loteo.fotoPrincipal
                              }
                            />
                            <Info>
                              <Category>{loteo.nombre}</Category>
                              <CreationDate>
                                {loteo.Localidad.nombre}
                              </CreationDate>
                              <Title>
                                {/*loteo.precioWeb && loteo.MonedaId ? loteo.precioWeb + "   " + loteo.Moneda.nombre : "Consulte Precio"*/}
                              </Title>
                              {loteo.featured && loteo.description && (
                                <Description>{loteo.observaciones}</Description>
                              )}
                            </Info>
                          </Post>
                        </PostContainer>
                      </>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>No hay Loteos para mostrar</>
            )}
          </Posts>
          {visible < loteos.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Ver más</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Pie />
    </AnimationRevealPage>
  );
};

/*
const getPlaceholderPost = () => ({
  imageSrc:
    "https://sipatagoniadigital.com/wp-content/uploads/2020/09/a830_generico-web05.jpg",
  category: "LOTEO QUILLINZO",
  date: "Manzana 11, Lote 5",
  title: "U$S 55.399",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  url: "/producto/detalle/lote/1/lote",
});
*/
