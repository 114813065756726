import React, { useEffect, useState } from "react";

import Checkbox from "@material-tailwind/react/Checkbox";
import Pie from "../../common/Pie";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton } from "components/misc/Buttons";
import ContactoFlotante from "pages/common/ContactoFlotante";

import * as PropiedadesServices from "services/productos/propiedadVentaWeb";

import GestorUrls from "../../../config/urls";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 140,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HeadingRow = tw.div` flex w-full   `;
const Heading = tw(
  SectionHeading
)` text-gray-100 pt-20  h-full text-3xl sm:text-5xl sm:text-4xl lg:text-5xl `;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostHeader = styled.div`
  ${tw`mt-10 w-full mt-0 sm:w-1/2 lg:w-1/3 sm:pr-8 `}
  ${(props) =>
    css`
      ${tw`w-full! `}
    `}
`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none  bg-orange-100  `;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div``;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

let campos = [null, null, null, null, null, null, null, null, null, null, null];

export default ({
  headingText = "Propiedades en venta",
  /*  posts = [
    
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
    getPlaceholderPost(),
  ],*/
}) => {
  const classes = useStyles();

  const [visible, setVisible] = useState(9);
  const [habitaciones, setHabitaciones] = React.useState("");
  const [ubicacion, setUbicacion] = useState(undefined);
  const [superficie, setSuperficie] = React.useState("");
  const [banios, setBanios] = React.useState("");
  const [cocheras, setCocheras] = React.useState("");
  const [precio, setPrecio] = React.useState("");
  const [luz, setLuz] = useState(true);
  const [agua, setAgua] = useState(true);
  const [gas, setGas] = useState(true);
  const [calefaccion, setCalefaccion] = useState(true);
  const [pileta, setPileta] = useState(true);

  const [localidades, setLocalidades] = React.useState("");
  const [cargando, setCargando] = useState(false);
  const [propiedades, setPropiedades] = useState({});
  const [filtradas, setFiltradas] = useState({});
  const [primeraCarga, setPrimeraCarga] = useState(true);

  const urlDrive = GestorUrls.drive;

  let vectorLocalidades = {};

  useEffect(() => {
    if (!cargando) {
      if (primeraCarga) {
        PropiedadesServices.filtrarUbicacion().then((res) => {
          setLocalidades(res);
          //console.log(res);
        });
        PropiedadesServices.visibleWeb().then((res) => {
          setPropiedades(res);
          setFiltradas(res);
          //console.log(res);
        });
        if (localidades && localidades.length) {
          vectorLocalidades = localidades.slice();
        }
        setPrimeraCarga(false);
      }
      setCargando(true);
    }
  }, [cargando, localidades, propiedades, filtradas]);

  const listaPrecios = [
    {
      desde: "Todas",
      hasta: "",
    },
    {
      desde: "0",
      hasta: "15000",
    },
    {
      desde: "15000",
      hasta: "20000",
    },
    {
      desde: "20000",
      hasta: "30000",
    },
    {
      desde: "30000",
      hasta: "50000",
    },
    {
      desde: "50000",
      hasta: "70000",
    },
    {
      desde: "70000",
      hasta: "100000",
    },
  ];

  const listaSuperficies = [
    {
      desde: "Todas",
      hasta: "",
    },
    {
      desde: "0",
      hasta: "100",
    },
    {
      desde: "100",
      hasta: "200",
    },
    {
      desde: "200",
      hasta: "300",
    },
    {
      desde: "300",
      hasta: "500",
    },
  ];

  const handleSearch = (e) => {
    setCargando(false);

    //console.log(campos);
    //console.log("Filtradas Origen: ", filtradas);

    let valor = e.target.value;
    let campo = e.target.name;
    let paraFiltrar = propiedades.slice();
    //console.log("Propiedades Origen: ", paraFiltrar);

    //console.log('Campo: ', campo, '. Valor: ', valor)

    if (campos[0] !== null) {
      let valores = campos[0].split("-");
      paraFiltrar = paraFiltrar.filter((f) => {
        if (f.precioWeb >= valores[0] && f.precioWeb <= valores[1]) {
          return f;
        }
      });
    }
    //console.log("Filtradas precio: ", paraFiltrar);

    if (campos[1] !== null) {
      //console.log(localidades[campos[1] - 1]);
      paraFiltrar = paraFiltrar.filter((f) => {
        //console.log('Localidad en lista: ', localidades[campos[1] - 1].nombre);
        //console.log('Localidad de producto:', f.Localidad, f.Localidad !== null, f.Localidad !== undefined);
        if (f.Localidad !== null && f.Localidad !== undefined){
          if (f.Localidad.nombre === localidades[campos[1] - 1].nombre) {
            return f;
          }  
        }
      });
    }
    //console.log("Filtradas localidad: ", paraFiltrar);

    if (campos[2] !== null) {
      paraFiltrar = paraFiltrar.filter((f) => {
        let valores = campos[2].split("-");
        if (
          parseFloat(f.superficieCubierta) >= parseFloat(valores[0]) &&
          parseFloat(f.superficieCubierta) <= parseFloat(valores[1])
        ) {
          return f;
        }
      });
    }
    //console.log("Filtrada superficie: ", paraFiltrar);

    if (campos[3] !== null) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (parseInt(f.cantBanios) === parseInt(campos[3])) {
          return f;
        }
      });
    }
    //console.log("Filtrada baños: ", paraFiltrar);

    if (campos[4] !== null) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (parseInt(f.cantCocheras) === parseInt(campos[4])) {
          return f;
        }
      });
    }
    //console.log("Filtrada cocheras: ", paraFiltrar);

    if (campos[5] !== null) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (parseInt(f.cantHabitaciones) === parseInt(campos[5])) {
          return f;
        }
      });
    }
    //console.log("Filtrada habitaciones: ", paraFiltrar);

    if (campos[6]) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (campos[6] && campos[6] === f.servLuz) {
          return f;
        }
      });
    }
    //console.log("Filtradas luz: ", paraFiltrar);

    if (campos[7]) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (campos[7] && campos[7] === f.servAgua) {
          return f;
        }
      });
    }
    //console.log("Filtradas agua: ", paraFiltrar);

    if (campos[8]) {
      paraFiltrar = paraFiltrar.filter((f) => {
        //console.log(campos[8] && campos[8] === f.servGas);
        if (campos[8] && campos[8] === f.servGas) {
          return f;
        }
      });
    }
    //console.log("Filtradas gas: ", paraFiltrar);

    if (campos[9]) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (campos[9] && campos[9] === f.calefaccion) {
          return f;
        }
      });
    }
    //console.log("Filtradas calefaccion: ", paraFiltrar);

    if (campos[10]) {
      paraFiltrar = paraFiltrar.filter((f) => {
        if (campos[10] && campos[6] === f.pileta) {
          return f;
        }
      });
    }

    //console.log("Filtrdas pileta: ", paraFiltrar);
    setFiltradas(paraFiltrar);
  };

  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };
  return (
    <AnimationRevealPage>
      <ContactoFlotante />
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <Posts>
            <PostHeader>
              <Image imageSrc="https://images.unsplash.com/photo-1499678329028-101435549a4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80">
                <Heading>{headingText}</Heading>
              </Image>
              <Info>
                <Category> BUSCADOR DE PROPIEDADES </Category>
                <Title> Seleccione para encontrar su mejor opción </Title>
                <Description>
                  <div class="flex  flex-col md:flex-row    justify-start gap-10 mt-3 mb-5 ...">
                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-precio">
                          Precio
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label-precio"
                          id="demo-simple-select-precio"
                          name="precio"
                          value={precio}
                          onChange={(event) => {
                            if (event.target.value === "Todas") {
                              campos[0] = null;
                            } else {
                              campos[0] = event.target.value;
                            }
                            setPrecio(event.target.value);
                            handleSearch(event);
                          }}
                        >
                          {listaPrecios.map((p) => {
                            return (
                              <MenuItem
                                value={
                                  p.desde === "Todas"
                                    ? p.desde
                                    : p.desde + "-" + p.hasta
                                }
                              >
                                {p.desde === "Todas"
                                  ? p.desde
                                  : p.desde + " a " + p.hasta}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-ubicacion">
                          Ubicación
                        </InputLabel>

                        {localidades ? (
                          <>
                            {localidades.length ? (
                              <>
                                <Select
                                  labelId="demo-simple-select-label-ubicacion"
                                  id="demo-simple-select-ubicacion"
                                  name="ubicacion"
                                  value={ubicacion}
                                  onChange={(event) => {
                                    if (event.target.value === 0) {
                                      campos[1] = null;
                                    } else {
                                      campos[1] = event.target.value;
                                    }
                                    setUbicacion(event.target.value);
                                    handleSearch(event);
                                  }}
                                >
                                  <MenuItem value={0}>Todas</MenuItem>
                                  {localidades.map((loc, index) => {
                                    return (
                                      <MenuItem value={index + 1}>
                                        {loc.nombre}-{loc.Provincium.nombre}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-superficie">
                          Sup. Cubierta
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-superficie"
                          id="demo-simple-select-superficie"
                          name="superficie"
                          value={superficie}
                          onChange={(event) => {
                            if (event.target.value === "Todas") {
                              campos[2] = null;
                            } else {
                              campos[2] = event.target.value;
                            }
                            setSuperficie(event.target.value);
                            handleSearch(event);
                          }}
                        >
                          {listaSuperficies.map((sup) => {
                            return (
                              <MenuItem
                                value={
                                  sup.desde === "Todas"
                                    ? sup.desde
                                    : sup.desde + "-" + sup.hasta
                                }
                              >
                                {sup.desde === "Todas"
                                  ? sup.desde
                                  : sup.desde + " a " + sup.hasta}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-banios">
                          Baños
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-banios"
                          id="demo-simple-select-banios"
                          name="banios"
                          value={banios}
                          onChange={(event) => {
                            if (event.target.value === 0) {
                              campos[3] = null;
                            } else {
                              campos[3] = event.target.value;
                            }
                            setBanios(event.target.value);
                            handleSearch(event);
                          }}
                        >
                          <MenuItem value={0}>Todas</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-cocheras">
                          Cocheras
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-cocheras"
                          id="demo-simple-select-cocheras"
                          name="cocheras"
                          value={cocheras}
                          onChange={(event) => {
                            if (event.target.value === 0) {
                              campos[4] = null;
                            } else {
                              campos[4] = event.target.value;
                            }
                            setCocheras(event.target.value);
                            handleSearch(event);
                          }}
                        >
                          <MenuItem value={0}>Todas</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <FormControl
                        variant="filled"
                        style={{ width: "100%" }}
                        className={classes.formControl}
                      >
                        <InputLabel id="demo-simple-select-label-habitaciones">
                          Habitaciones
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label-habitaciones"
                          id="demo-simple-select-habitaciones"
                          name="habitaciones"
                          value={habitaciones}
                          onChange={(event) => {
                            if (event.target.value === 0) {
                              campos[5] = null;
                            } else {
                              campos[5] = event.target.value;
                            }
                            setHabitaciones(event.target.value);
                            handleSearch(event);
                          }}
                        >
                          <MenuItem value={0}>Todas</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={6}>6</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={8}>8</MenuItem>
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div class="flex  flex-col md:flex-row    justify-start gap-10 mt-3 mb-5 ...">
                    <div>
                      {" "}
                      <Checkbox
                        color="green"
                        text="Luz"
                        id="checkbox3"
                        name="luz"
                        value={luz}
                        onChange={(e) => {
                          campos[6] = luz;
                          setLuz(!luz);
                          handleSearch(e);
                        }}
                      />
                    </div>

                    <div>
                      {" "}
                      <Checkbox
                        color="green"
                        text="Agua"
                        id="checkbox4"
                        name="agua"
                        value={agua}
                        onChange={(e) => {
                          campos[7] = agua;
                          setAgua(!agua);
                          handleSearch(e);
                        }}
                      />
                    </div>

                    <div>
                      {" "}
                      <Checkbox
                        color="green"
                        text="Gas natural"
                        id="checkbox5"
                        name="gas"
                        value={gas}
                        onChange={(e) => {
                          campos[8] = gas;
                          setGas(!gas);
                          handleSearch(e);
                        }}
                      />
                    </div>

                    <div>
                      {" "}
                      <Checkbox
                        color="green"
                        text="Calefacción"
                        id="checkbox2"
                        name="calefaccion"
                        onChange={(e) => {
                          campos[9] = calefaccion;
                          setCalefaccion(!calefaccion);
                          handleSearch(e);
                        }}
                      />
                    </div>

                    <div>
                      {" "}
                      <Checkbox
                        color="green"
                        text="Pileta"
                        id="checkbox"
                        name="pileta"
                        value={pileta}
                        onChange={(e) => {
                          campos[10] = pileta;
                          setPileta(!pileta);
                          handleSearch(e);
                        }}
                      />
                    </div>
                  </div>
                </Description>
              </Info>
            </PostHeader>
            {filtradas ? (
              <>
                {filtradas.length ? (
                  <>
                    {filtradas.slice(0, visible).map((post, index) => (
                      <PostContainer key={index} featured={post.featured}>
                        <Post
                          className="group"
                          as="a"
                          href={
                            "/producto/detalle/propiedad/" +
                            post.id +
                            "/" +
                            post.nombre.replace(/ /g, "-")
                          }
                        >
                        {console.log("URL foto",  urlDrive +
                              "productos/propsventa/" +
                              post.id +
                              "/" +
                              post.fotoPrincipal)}
                          <Image
                            imageSrc={
                              urlDrive +
                              "productos/propsventa/" +
                              post.id +
                              "/" +
                              post.fotoPrincipal
                            }
                          />
                          <Info>
                            <Category>{post.LocalidadId ? post.Localidad.nombre : ""}</Category>
                            <CreationDate>{post.nombre ? post.nombre : ""}</CreationDate>
                            <Title>{post.precioWeb && post.MonedaId ? new Intl.NumberFormat().format(post.precioWeb) + "   " + post.Moneda.simbolo : "Consulte Precio"}</Title>
                            {post.featured && post.description && (
                              <Description>{post.description}</Description>
                            )}
                          </Info>
                        </Post>
                      </PostContainer>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Posts>
          {visible < filtradas.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>Ver más</LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Pie />
    </AnimationRevealPage>
  );
};

/*
const getPlaceholderPost = () => ({
  imageSrc:
    "https://media.remax-dev.booj.io/be91b44c-0c61-379f-839c-ef2dc2afd4e3/02_LuxuryHomes.jpg",
  category: "SANTA ROSA DE CALAMUCHITA",
  date: "CASA DEMO 1",
  title: "U$S 560.340",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    url: "/producto/detalle/lote/1/propiedad",
});
*/
