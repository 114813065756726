import React from "react";
//import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../../components/misc/Headings.js";
import { Subheading2 as SubHeadingTitle } from "../../components/misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
 
const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
/*const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;*/

export default (props) => {
  
  const { titulo, subTitulo, detalle, precio } = props;
 
  return (
    <Container style={{ marginBottom: -60}}>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle> {titulo}</HeadingTitle>
          <HeadingDescription>
        <SubHeadingTitle>  {subTitulo} </SubHeadingTitle>
        <p> {detalle} </p>
        <br /> 
        <SubHeadingTitle  > {precio}</SubHeadingTitle> 
          </HeadingDescription>
        </HeadingInfoContainer>
 
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
     
       
    </Container>
  );
};
