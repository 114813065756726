import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import parse from "html-react-parser";
//eslint-disable-next-line
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import ContactoFlotante from "pages/common/ContactoFlotante";

import LotesXLoteo from "../lotes/LotesXLoteo";

const PrimaryButton = tw(PrimaryButtonBase)`w-full mt-8`;
const MetaContainer = tw.div`flex items-center mb-2`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;
const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full mb-10`;
const Description = tw(SectionDescription)`w-full text-center`;
const Paragraph = tw(SectionHeading)`w-full text-left text-xl mb-20 ml-40`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }
  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }
  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }
  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Title = tw.h2`text-2xl sm:text-2xl font-black tracking-wide text-center`

export default (
  props,
  {
    cards = null,
    heading = "",
    subheading = "",
    description = "Si necesita más información, conocer planes de pago / financiación, rellene el fomulario y nos pondremos en contacto.",
  }
) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  //heading = props.nombre;

  const { producto, lotes, manzanas, estados, desc } = props;

  console.log('Detalle: ', producto, lotes, manzanas, estados);
  console.log('Tipo: ', typeof producto.descripcionWeb);

  return (
    <Container style={{ marginBottom: -70 }}>
      <ContactoFlotante />
      <ThreeColumnContainer>
        <Paragraph>
          {producto.descripcionWeb &&
            parse(producto.descripcionWeb)
          }
        </Paragraph>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
        <VerticalSpacer />

        <Column>
          <Card>
            <span className="textContainer">
              <span className="title">Precio</span>
            <Title>{producto.precioWeb && producto.MonedaId ? new Intl.NumberFormat().format(producto.precioWeb) + "   " + producto.Moneda.nombre : "Consulte Precio"}</Title>
              {/*<p className="description text-xl">{producto.precioWeb && producto.MonedaId ? new Intl.NumberFormat().format(producto.precioWeb) + "   " + producto.Moneda.nombre : "Consulte Precio"}</p>*/}
            </span>
          </Card>
        </Column>

        <Column>
          <Card>
            <span className="textContainer">
              {(producto.servLuz || producto.servAgua || producto.servGas) && 
                <>
                  <span className="title">Servicios en {desc === "loteos" ? "el loteo" : "la propiedad"}</span>
                  <p className="description">
                    {producto.servLuz && (
                      <MetaContainer>
                        <Meta>
                          <TagIcon />
                          <div>Luz eléctrica</div>
                        </Meta>
                      </MetaContainer>
                    )}

                    {producto.servAgua && (
                      <MetaContainer>
                        <Meta>
                          <TagIcon />
                          <div>Agua corriente</div>
                        </Meta>
                      </MetaContainer>
                    )}

                    {producto.servGas && (
                      <MetaContainer>
                        <Meta>
                          <TagIcon />
                          <div>Gas natural</div>
                        </Meta>
                      </MetaContainer>
                    )}
                  </p>
                </>
              }
            </span>
          </Card>
        </Column>
        <Column>
          <Card>
            <span className="textContainer">
              <span className="title">Ubicación </span>
              <p className="description">
                Para ver la zona aproximada, hacer clic en el siguiente botón
                {producto.latitud !== 0 && producto.longitud !== 0 ?
                  <>
                    <a href={`https://www.google.com.ar/maps/@${producto.latitud},${producto.longitud},19.5z`} target="_blank"><PrimaryButton>Ver en mapa</PrimaryButton></a>
                  </>
                  :
                  <>
                    <PrimaryButton>Info no disponible</PrimaryButton>
                  </>
                }

              </p>
            </span>
          </Card>{" "}
        </Column>

        {desc === "loteos" &&
        <>
          {producto.linkPlano &&
            <>
              <Heading style={{marginTop: 30}}>Plano</Heading>
              <div style={{width: "100%", height: 1, backgroundColor: "black", marginBottom: 20}} ><hr/></div>
              <div><img src={producto.linkPlano} alt="" /></div>
              <div style={{width: "100%", height: 1, backgroundColor: "black", marginTop: 20}} ><hr/></div>
            </>
          }
          {/*producto &&
            <LotesXLoteo producto={producto} lotes={lotes} manzanas={manzanas} estados={estados} />
          */}
        </>
        }
        {producto.linkVideo && 
        <>
        <VerticalSpacer />
        <Subheading> VIDEO PROMOCIONAL </Subheading>
        <VerticalSpacer />
        <iframe
          width="100%"
          height="600"
          src={producto.linkVideo}//"https://www.youtube.com/embed/fHJnBz12n_8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        </>
        }
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};