import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/shopping-cart.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import * as LotesxloteowebServices from "services/productos/lotesXLoteoWeb";
import * as PropiedadesVentawebServices from "services/productos/propiedadVentaWeb";
import * as LoteosServices from "services/productos/loteosWeb"

import GestorUrls from "../../config/urls";
import { prototype } from "react-modal";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-xs font-semibold text-gray-800`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;


export default (props) => {
  //Prop para no repetir el componente
  const { tipo } = props;

  const urlDrive = GestorUrls.drive;

  const [producto, setProducto] = useState("");

  // Genera un json desde los lotes que recibe, para igualar los datos y no repetir componente
  // Falta agregar elementos para el momento que se haga click en "VER MAS" 
  function igualarLotes (lotes) {
    //console.log('Loteos: ', loteos);

    let v = [];
    for (let i = 0; i < lotes.length; i++) {
      let objJson = {
          id: lotes[i].LoteoId,
          nombre: acortarNombres(lotes[i].Loteo.nombre + " - " + lotes[i].nombre, 20),
          nombreCompleto: lotes[i].Loteo.nombre + "-" + lotes[i].manzana + "-" + lotes[i].nombre,
          nombreDescripcion:acortarNombres(lotes[i].Loteo.nombre + " - Mza: " + lotes[i].manzana + " - Lote: " + lotes[i].nombre, 30),
          precio: new Intl.NumberFormat().format(lotes[i].precioWeb),
          descripcion: lotes[i].observaciones,
          rating: 4.8,
          imagen: "productos/loteos/" + lotes[i].LoteoId + "/" + lotes[i].Loteo.fotoPrincipal,
          card: tipo + i.toString(),
        }
        v.push(objJson);
        //console.log("AGREGA ITEM LOTE", objJson);
      }
      setProducto("lote");
      //console.log("VECTOR RESULTADO LOTE", v)
      return (v);
  }

  function acortarNombres (nombre, maximo) {
    let cadena = nombre
    if (cadena.length > maximo) {
      cadena = cadena.substring(0, maximo - 3) + "...";
    } else if (cadena.length < 20) {
      cadena = cadena + "          ";
    }
    return cadena.toUpperCase();
  }

  // Genera un json desde las propiedades que recibe, para igualar los datos y no repetir componente
  // Falta agregar elementos para el momento que se haga click en "VER MAS" 
  function igualarPropiedades (propiedades) {

    let v = [];
    for (let i = 0; i < propiedades.length; i++) {
      let objJson = {
          id: propiedades[i].id,
          nombre: acortarNombres(propiedades[i].nombre, 20),
          nombreCompleto: propiedades[i].nombre,
          nombreDescripcion: acortarNombres(propiedades[i].nombre, 30),
          precio: new Intl.NumberFormat().format(propiedades[i].precioWeb),
          descripcion: propiedades[i].observaciones,
          rating: 4.8,
          imagen: "productos/propsventa/" + propiedades[i].id + "/" + propiedades[i].fotoPrincipal,
          card: tipo + i.toString(),
        }
        v.push(objJson);
        //console.log("AGREGA ITEM PROPIEDAD", objJson);
      }
      setProducto("propiedad");
      //console.log("VECTOR RESULTADO PROP", v)
    return (v);
  }


  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [cargando, setCargando] = useState(false);
  const [productos, setProductos] = useState({});
  //const [completo, setCompleto] = useState({});
  

  useEffect(() => {
    if (!cargando) {
      setCargando(true);

      // Si desde las props viene tipo = lotes, carga los elementos desde lotes
      // Caso contrario, carga desde propiedades
      if (tipo === "loteos") {
        LotesxloteowebServices.destacados().then((res) => {
          setProductos(igualarLotes(res));
          //console.log('Loteos: ', res);
        });
      } else {
        PropiedadesVentawebServices.destacados().then((res) => {
          setProductos(igualarPropiedades(res));
        });  
      }
  }
  }, [cargando, productos]);

  const [sliderRef, setSliderRef] = useState(null);

  let cantidadProductos = 0;
  if(productos && productos.length)
  {
    if(productos.length > 3)
    {
      cantidadProductos = 3;
    }else
    {
      cantidadProductos = productos.length;
    }

  }

  const sliderSettings = {
    arrows: false,
    slidesToShow: cantidadProductos,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: cantidadProductos > 2 ? 2 : 1,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
 
  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Oportunidades - {tipo}</Heading>
          {productos && productos.length > cantidadProductos ?
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              {/*console.log(sliderRef)*/}
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              {/*console.log(sliderRef)*/}
              <ChevronRightIcon />
            </NextButton>
          </Controls>
          :
          <> </>
          }
        </HeadingWithControl>
        {productos ? (
          <>
          {productos.length ?
            <>
            <CardSlider ref={setSliderRef} {...sliderSettings}>
              {productos.map((item, index) => (
                <>
                {/*console.log("*** PROCESA item: ", index, item)*/
                }
                  <Card key={item.card}>
                    <CardImage
                      imageSrc={urlDrive + item.imagen}
                    />
                    <TextInfo>
                      <TitleReviewContainer>
                        <Title>
                          {item.nombre}
                        </Title>
                      </TitleReviewContainer>
                      <SecondaryInfoContainer>
                        <IconWithText>
                          <IconContainer>
                            <LocationIcon />
                          </IconContainer>
                          <Text>{item.nombreDescripcion}</Text>
                        </IconWithText>
                        <IconWithText>
                          <IconContainer>
                            <PriceIcon />
                          </IconContainer>
                          <Text>{item.precio}</Text>
                        </IconWithText>
                      </SecondaryInfoContainer>
                      <Description>{item.observaciones}</Description>
                    </TextInfo>
                    <a href={"/producto/detalle/" + producto + "/" + item.id + "/" + item.nombreCompleto.replace(/ /g, "-")}><PrimaryButton>Ver más</PrimaryButton></a>
                  </Card>
                </>
              ))}
            </CardSlider>
            </>
            :
            <>
              No hay productos en la lista
            </>
          }
          </>
        ) : (
          <>  No hay productos en la lista</>
        )}
      </Content>
    </Container>
  );
};
