import React from 'react'


import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import './contactoFlotante.css';

const ContactoFlotante = () => {
  return (
    <>
    <div style={{textAlign: "center"}}>
      <a
        href="https://wa.me/5493546538424"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      > 
      <WhatsAppIcon style={{fontSize: 40}}/>
      </a>

    </div>
    </>
  );
}

export default ContactoFlotante