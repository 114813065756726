import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
 
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
 
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import FotoLote from "../../images/gdv/home-loteos/lotesselector.jpg"
import FotoPropiedad from "../../images/gdv/home-propiedades/propselector.jpg"
 
const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

const PrimaryLink2 = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

const ActionButton = tw(
  PrimaryLinkBase
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal w-full`;
 


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;
 

const CardTitle = tw.h5`text-xl mt-4 font-bold text-center`;

 
const CardAction = tw(ActionButton)`w-full mt-8`;

export default () => {
  const cards = [
    {
      imageSrc:
        FotoLote,
      
      title: "LOTEOS",
      link: "/producto/venta/lotes",
    },
    {
      imageSrc:
        FotoPropiedad,
      
      title: "PROPIEDADES EN VENTA",
      link: "/producto/venta/propiedades",
      
    }
  ];
  return (
    <Container>
          <a id='productos'></a>
      <Content>
       
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Nuestros productos</HeadingTitle>
              <HeadingDescription>
              Somos un Gran equipo con una amplia cartera de Inmuebles en el Valle de Calamuchita. Nuestro objetivo es brindar un servicio inmobiliario integral y de calidad a todos nuestros clientes. Queremos mostrarte la imponente naturaleza del Valle.
                <br /><br />
              </HeadingDescription>
              <PrimaryLink>
                Selecciona una opción <ArrowRightIcon />
              </PrimaryLink>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} />
                <CardText>
                  
                  <CardTitle>{card.title}</CardTitle>
                   
                  <CardAction href={card.link}>ENTRAR</CardAction>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
