import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
//import Foto from "images/gdv/cabecera/home.jpeg";
import Carousel from "react-gallery-carousel";
import Imagen1 from "images/gdv/banner/1.jpg";
import Imagen2 from "images/gdv/banner/2.jpg";
import Imagen3 from "images/gdv/banner/3.jpg";
import Imagen4 from "images/gdv/banner/4.jpg";
import Imagen5 from "images/gdv/banner/5.jpg";
import Imagen6 from "images/gdv/banner/6.jpeg";
import Imagen7 from "images/gdv/banner/7.jpg";
import Imagen8 from "images/gdv/banner/8.jpg";

const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded-full bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-1 bg-center bg-cover h-screen min-h-144 ml-5`}  
`; // Modificado. imagen eliminada para poder poner Carrusel

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75 -ml-10`; // Modificado para lograr ancho ajustable con Carrusel mr-10 -ml-10

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

let imagenes = [
  {src: Imagen1}, 
  {src: Imagen2}, 
  {src: Imagen3}, 
  {src: Imagen4}, 
  {src: Imagen5}, 
  {src: Imagen6}, 
  {src: Imagen7}, 
  {src: Imagen8},
];

export default () => {
  return (
    <Container>
      <div style={{position: "absolute"}}>
        <Carousel
          images={imagenes}
          hasMediaButton={false}
          hasSizeButton={false}
          hasThumbnails={false}
          hasLeftButton={false}
          hasRightButton={false}
          isAutoPlaying={true}
          autoPlayInterval={5000}
          style={{width: "auto"}}
        />
      </div>
      <OpacityOverlay />
      <HeroContainer>
        <Content>
          <Heading>
            El lugar soñado,
            <br />a tu alcance
          </Heading>

          <PrimaryButton href="#productos" css="">
            INICIAR BUSQUEDA
          </PrimaryButton>
        </Content>
      </HeroContainer>
    </Container>
  );
};
