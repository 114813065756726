import GestorAxios from "../../config/axios";


export async function cargarGaleria(path) {
  //console.log('Desde services: ', path);
  let dataReturn = {};
  await GestorAxios.post('/web/galeria/cargargaleria/', path).then((res) => {
    dataReturn = res;
  });

  return dataReturn;
}
