import GestorAxios from "../../config/axios";

export async function destacados() {
  let data = {};

  try
  {
  await GestorAxios.get("/web/propiedadesventaweb/destacado/").then((res) => {
    data = res.data;
    console.log('Destacados desde Services Propiedad: ', res.data);
  });
} catch (e) {
  console.log('ERROR EN SERVICIO ->Destacados desde Services Propiedad: ', e);
}
  return data;
}


export async function visibleWeb() {
  let data = {};

  try{
  await GestorAxios.get("/web/propiedadesventaweb/visibleweb").then((res) => {
    data = res.data;
    console.log('Destacados desde Services Propiedad propiedadesventaweb: ', res.data);
  });
  } catch (e) {
    console.log('ERROR EN SERVICIO ->propiedadesventaweb Propiedad: ', e);
  }
  return data;
}


export async function filtrarWeb(id) {
  let data = {};
  try{
  await GestorAxios.get("/web/propiedadesventaweb/filtrarweb/" + id).then((res) => {
    data = res.data;
    console.log('Destacados desde Services Propiedad filtrarweb: ', res.data);
  });
} catch (e) {
  console.log('ERROR EN SERVICIO ->filtrarweb Propiedad: ', e);
}
  return data;
}


export async function filtrarUbicacion() {
  let data = {};
  try{
  await GestorAxios.get("/web/propiedadesventaweb/filtrarubicacion").then((res) => {
    data = res.data;
    console.log('Destacados desde Services Propiedad filtrarubicacion: ', res.data);
  });
} catch (e) {
  console.log('ERROR EN SERVICIO ->filtrarubicacion Propiedad: ', e);
}
  return data;
}
