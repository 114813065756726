import GestorAxios from "../../config/axios";

export async function visibleWeb() {
  let data = {};

  try {
    await GestorAxios.get("/web/loteos/visibleWeb").then((res) => {
      data = res.data;
      console.log('Destacados desde loteos Services visibleWeb: ', res.data);
    });
  } catch (e) {
    console.log("error en servicio LOTEO");
  }

  return data;
}

export async function filtrarWeb(id) {
  let data = {};

  try {
  await GestorAxios.get("/web/loteos/filtrarWeb/" + id).then((res) => {
    data = res.data;
    console.log('Destacados desde loteos Services filtrarWeb: ', res.data);
  });
} catch (e) {
  console.log("error en servicio filtrarWeb LOTEO");
}

  return data;
}
