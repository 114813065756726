import GestorAxios from "../../config/axios";


export async function getAllItems() {
  let data = {};

  try
  {
  await GestorAxios.get("/web/estado/").then((res) => {
    data = res.data;
  })
}
catch (e) {
  console.log("ERror en servicio Estados");
}

  return data;
  
}
