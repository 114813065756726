import axios from 'axios';

import GestorUrls from './urls';

// Setting para uso LOCAL

const GestorAxios = axios.create({
    baseURL : GestorUrls.api
});


// Setting para uso en el SERVER
/*
const GestorAxios = axios.create({
    baseURL : 'https://api-ssl.grupodelvalleinmobiliaria.com'
});

*/
export default GestorAxios;