import GestorAxios from "../../config/axios";

export async function destacados() {
  let data = {};

  try{
  await GestorAxios.get("/web/lotesxloteoweb/destacado/").then((res) => {
    data = res.data;
    console.log('Destacados desde Services Lotes: ', res);
  })
}
  catch(e){
    console.log('ERROR EN SERVICIO Destacados desde Services Lotes: ', e);
  }
  return data;
}


export async function listadoWeb(id) {
  let data = {};
  try{
  await GestorAxios.get("/web/lotesxloteoweb/listadoweb/" + id + "/").then((res) => {
    data = res.data;
  })}
  catch(e){
    console.log('ERROR EN SERVICIO listadoweb desde Services Lotes: ', e);
  }

  return data;
}
