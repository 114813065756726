import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
//import { motion } from "framer-motion";
import { components } from "ComponentRenderer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
//import { ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import ContactoFlotante from "pages/common/ContactoFlotante";
//import { css } from "styled-components/macro";

//import { SectionHeading as HeadingBase } from "components/misc/Headings";
//import { SectionDescription as DescriptionBase } from "components/misc/Typography";
//import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

//import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
//import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

import Carousel from "react-gallery-carousel";
//import 'react-gallery-carousel/dist/index.css';
import "./index.css";

import GestorUrls from "config/urls";

import Cabecera from "../../common/Cabecera";
import Titulo from "../../common/Titulo";
import Formulario from "../../common/Formulario";

import Detalle from "./Detalle";

import Pie from "../../common/Pie";

import * as LoteoServices from "services/productos/loteosWeb";
import * as LotexLoteoWebServices from "services/productos/lotesXLoteoWeb";
import * as GaleriaServices from "services/utiles/galeria";
import * as EstadoServices from "services/productos/estadoWeb";
 
import useInView from "@owaiswiz/use-in-view";

/* Hero */
//const Row = tw.div`flex`;

//const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
//const PrimaryNavLink = tw(NavLink)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
//const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 pb-16 max-w-screen-2xl mx-auto flex-wrap`;

//const Column = tw.div`flex-1`;

//const ActionButton = tw(AnchorLink)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}.resizehandlewrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

export default ({
  features = null,

  landingPages = components.landingPages,
  innerPages = components.innerPages,
  blocks = components.blocks,
}) => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */

  const { id, desc } = useParams();

  const urlDrive = GestorUrls.drive;

  const [cargando, setCargando] = useState(false);
  const [loteo, setLoteo] = useState({});
  const [imagenes, setImagenes] = useState({});
  const [todosLotes, setTodosLotes] = useState({});
  const [cantManzanas, setCantManzanas] = useState([]);
  const [estados, setEstados] = useState([]);

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      let path = "productos/loteos/" + id + "/galeria/";
      LoteoServices.filtrarWeb(id).then((res) => {
        setLoteo(res[0]); 
        setCantManzanas(res[0].cantManzanas.split(","));
        //console.log("Desde Lote ----- Loteo: ", res[0]);
      });

      LotexLoteoWebServices.listadoWeb(id).then((res) => {
        setTodosLotes(res);
        //console.log('Todos los lotes del loteo: ', res);
      })

      EstadoServices.getAllItems().then((res) => {
        setEstados(res);
        console.log('Estados: ', res);
      })

      let data = {
        path: path,
        id: id,
      };
      GaleriaServices.cargarGaleria(data).then((res) => {
        let img = res.data.map((i, index) => {
          return { src: urlDrive + path + i };
        });
        setImagenes(img);
        //console.log("Imagenes: ", res.data);
      });
    }
  }, [loteo, imagenes, cantManzanas, estados]);

  //console.log("LLEGA: ", id, desc);

  window.gtag("js", new Date());
  window.gtag("config", "UA-1111-1");

  const noOfLandingPages = Object.keys(landingPages).length;
  const noOfInnerPages = Object.keys(innerPages).length;
  const noOfComponentBlocks = Object.values(blocks).reduce(
    (acc, block) => acc + Object.keys(block.elements).length,
    0
  );

  features = features || [
    `${noOfLandingPages} Landing Page Demos`,
    `${noOfInnerPages} Inner Pages`,
    `${noOfComponentBlocks} Components`,
    "Uses TailwindCSS",
    "Fully Responsive",
    "Fully Customizable",
  ];
  /*
  const images = [
    {src: "https://images.pexels.com/photos/2079234/pexels-photo-2079234.jpeg",},
    {src: "https://images.pexels.com/photos/2581922/pexels-photo-2581922.jpeg",},
    {src: "https://images.pexels.com/photos/4530189/pexels-photo-4530189.jpeg",},
    {src: "https://images.pexels.com/photos/186077/pexels-photo-186077.jpeg" },
    {src: "https://images.pexels.com/photos/1029599/pexels-photo-1029599.jpeg",},
  ];
*/

  return (
    <AnimationRevealPage>
      <Cabecera />
    
      {loteo.Localidad &&
      <Titulo
        titulo="Detalle de Loteo"
        subTitulo={loteo.nombre}
        detalle={loteo.Localidad.nombre}
        precio="" //{`Desde ${loteo.precioWeb} U$S`}
      />
      }

      {imagenes ? (
        <>
          {imagenes.length ? (
            <>
              <Carousel images={imagenes} />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {loteo && cantManzanas && estados && todosLotes ?
      <>
      {/*console.log('Lote para Detalle: ', cantManzanas)*/}
      <Detalle producto={loteo} lotes={todosLotes} manzanas={cantManzanas} estados={estados} desc="loteos" />

      <Formulario producto={loteo} desc="loteos" />
      </>
      :
      <>No hay informacion de Loteo</>
      }

      <Pie />
    </AnimationRevealPage>
  );
};

const BlocksRenderer = ({ blocks }) => {
  const [lastVisibleBlockIndex, setLastVisibleBlockIndex] = useState(0);

  const updateLastVisibleBlockIndex = (index) => {
    //console.log("LAST WAS ", lastVisibleBlockIndex);
    if (index > lastVisibleBlockIndex) setLastVisibleBlockIndex(index);
  };

  return (
    <ComponentsContainer>
      {blocks.map(
        (block, index) =>
          lastVisibleBlockIndex + 1 >= index && (
            <Block
              key={index}
              components={block}
              notifyIsVisible={() => updateLastVisibleBlockIndex(index)}
            />
          )
      )}
    </ComponentsContainer>
  );
};

const Block = ({ notifyIsVisible, components }) => {
  const offset = 30;
  const [ref, inView] = useInView(offset);

  useEffect(() => {
    if (inView) notifyIsVisible();
  }, [inView, notifyIsVisible]);

  const ResizeHandle = (
    <ResizeHandleButton>
      <HandleIcon tw="w-4 h-4 text-gray-600" />
    </ResizeHandleButton>
  );

  const componentBlockRefs = {};

  const updateComponentBlockIframeHeight = (iframe) => {
    iframe.style.height = "auto";
    iframe.style.height =
      iframe.contentWindow.document.body.scrollHeight + "px";
  };

  return (
    <div ref={ref} tw="mt-32">
      <ComponentsType>{components.type}</ComponentsType>
      <Components>
        {Object.values(components.elements).map((component, componentIndex) => (
          <Component key={componentIndex}>
            <ComponentHeading>
              <ComponentName>{component.name}</ComponentName>
              <ComponentPreviewLink
                className="group"
                href={component.url}
                target="_blank"
              >
                View Live Demo{" "}
                <ArrowRightIcon tw="transition duration-300 transform group-hover:translate-x-px ml-2 w-4 h-4" />
              </ComponentPreviewLink>
            </ComponentHeading>
            <ComponentContent>
              <ResizableBox
                minWidth={310}
                default={{
                  width: "100%",
                  height: "100%",
                }}
                bounds="parent"
                disableDragging={true}
                enableResizing={{ right: true }}
                resizeHandleComponent={{ right: ResizeHandle }}
                resizeHandleWrapperClass={`resizeHandleWrapper`}
                onResize={() =>
                  updateComponentBlockIframeHeight(
                    componentBlockRefs[component.url]
                  )
                }
              >
                <iframe
                  src={component.url}
                  title="Hero"
                  width="100%"
                  ref={(ref) => (componentBlockRefs[component.url] = ref)}
                  onLoad={(e) => updateComponentBlockIframeHeight(e.target)}
                />
              </ResizableBox>
            </ComponentContent>
          </Component>
        ))}
      </Components>
    </div>
  );
};
