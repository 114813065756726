import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/gdv/Logo-por-partes-blanco.png";
//import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
//import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
//import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-80`;
//const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
//const H6 = tw.h5;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
           
          </LogoContainer>
          <LinksContainer>
          <Link href="/">INICIO</Link>
      <Link href="/producto/venta/lotes">LOTEOS</Link>
      <Link href="/producto/venta/propiedades">PROPIEDADES EN VENTA</Link>
      <Link href="/contactar">CONTACTAR</Link>
           
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.instagram.com/inmobiliariagrupodelvalle/?hl=es" target="_blank">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/inmobiliariagrupodelvalle/" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UCiXgBM8_iIXU2vy5sSyNMsw" target="_blank">
              <YouTubeIcon />
            </SocialLink>
            <SocialLink href="https://api.whatsapp.com/send?phone=5493546538424&text=hola%2C%20necesito%20informaci%C3%B3n%20sobre...%20" target="_blank">
              <WhatsAppIcon />
            </SocialLink>
            <SocialLink href="http://maps.google.com/maps?f=q&q=-32.0634789,-64.5417481&z=16.5z" target="_blank">
              <LocationOnIcon />
            </SocialLink>
          </SocialLinksContainer>
          <div style={{marginTop: 20, marginBottom:10, color: "lightgrey"}}>
          <h6>
            Av. Int. R. Giagetto 771, Santa Rosa de Calamuchita | grupodelvallesrl@gmail.com | 03546 65-0147
          </h6>
          </div>
          <CopyrightText>
            &copy; Copyright 2021, Grupo del Valle inmobiliaria. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
