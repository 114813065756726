import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
//import {PrimaryButton} from "components/misc/Buttons.js";

import * as ConsultaWeb from "../../services/clientes/consultaWeb";
import { TripOriginOutlined } from "@material-ui/icons";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
//const Button = tw(PrimaryButton)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-green-500 text-gray-100 hocus:bg-green-700 hocus:text-gray-300 border border-green-500 hocus:border-green-700`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contactar",
  heading = <>Deseo <span tw="text-primary-500">más información</span><wbr/> sobre...</>,
  description = "Rellena el siguiente formulario e indica tu consulta, en breve nos pondremos en contacto.",
  submitButtonText = "ENVIAR",
  //formAction = "#",
  //formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [values, setValues] = useState({
    nombre: "",
    email: "",
    telefono: "",
    consulta: ""
  });
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [consulta, setConsulta] = useState("");
  const [cargando, setCargando] = useState(false);
  const [mensaje, setMensaje] = useState("");

  

  const handleSubmit = (e) => {
    let data = {
      email: email,
      nombre: nombre,
      telefono: telefono,
      consulta: consulta,
      origen: "CONTACTO"
    }
    console.log('Datos: ', data);
    try {
      ConsultaWeb.insert(data).then((res) => {
        setMensaje("Enviado correctamente");
      }).catch((error) => {
        setMensaje("Fallo al enviar correo. Contacte por WhatsApp");
      });  
    } catch { console.log("Error verificar")    }

  setCargando(false);
  }

  useEffect(() => {
    if (!cargando) {
      setCargando(true);
      setNombre("");
      setEmail("");
      setTelefono("");
      setConsulta("");
    }
  }, [cargando]);


  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form >
              <Input type="email" name="email" placeholder="Tu dirección de email" onChange={(e) => {setEmail(e.target.value)}} value={email} />
              <Input type="text" name="nombre" placeholder="Nombre" onChange={(e) => {setNombre(e.target.value)}} value={nombre} />
              <Input type="text" name="telefono" placeholder="Teléfono" onChange={(e) => {setTelefono(e.target.value)}} value={telefono} />
              <Textarea name="consulta" placeholder="Envía un mensaje indicando el motivo de tu consulta" onChange={(e) => {setConsulta(e.target.value)}} value={consulta} />
              <SubmitButton type="button" onClick={handleSubmit}>{submitButtonText}</SubmitButton>
              <div style={{color: "grey", marginTop: 30}}>
                <h3>{mensaje}</h3>
              </div>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
